<template>
    <ValidationObserver ref="observer">
        <b-form>
            <b-row>
                <b-col cols="12" lg="12">
                    <b-card no-body>
                        <b-card-header>
                            <strong>تفاصيل الحساب</strong>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <EKInputText
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الاسم والكنية مطلوبين'
                                            }
                                        ]"
                                        label="الاسم والكنية"
                                        v-model="accountDto.fullName"
                                        placeholder="ادخل الاسم والكنية"
                                        name="fullName"
                                    />
                                </b-col>
                                <b-col cols="12" md="6">
                                    <EKInputText
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'اسم المستخدم مطلوب'
                                            },
                                            { type: 'username', message: 'اسم المستخدم يطول (5 - 29) ويقبل المحارف a-z, A-Z, 0-9, _' }
                                        ]"
                                        label="اسم المستخدم"
                                        v-model="accountDto.username"
                                        placeholder="ادخل اسم الستخدم"
                                        name="userName"
                                    />
                                </b-col>
                                <b-col cols="12" md="6">
                                    <EKInputText
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'كلمة السر مطلوبة'
                                            }
                                        ]"
                                        label="كلمة السر"
                                        v-model="accountDto.password"
                                        placeholder="ادخل كلمة السر"
                                        name="password"
                                    />
                                </b-col>
                                <b-col cols="12" md="6">
                                    <EKInputSelect
                                        label="المنصب"
                                        placeholder="المنصب"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الحقل إجباري'
                                            }
                                        ]"
                                        :options="rolesList"
                                        name="select"
                                        v-model="accountDto.userType"
                                        :clearable="true"
                                    />
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer class="border-0 pt-0 d-flex">
                            <b-button
                                @click.prevent="onSubmit"
                                size="md"
                                variant="primary"
                            >
                                تعديل
                            </b-button>
                            <b-button
                                @click="deleteAccount(id)"
                                size="md"
                                variant="outline-danger"
                                class="ml-1 mr-auto"
                            >
                                حذف
                            </b-button>
                            <b-button
                                :to="`/admin/accounts`"
                                size="md"
                                variant="outline-primary"
                            >
                                تراجع
                            </b-button>
                        </b-card-footer>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";

import { nullGuid } from "@Ekcore/util/global.js";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
export default {
    components: {
        ValidationObserver,
        EKInputText,
        EKInputSelect
    },
    props: {
        id: String
    },
    data: () => ({
        nullGuid
    }),
    computed: {
        ...mapState({
            accountDto: state => state.accounts.accountDto,
            rolesList: state => state.accounts.rolesList
        })
    },
    created() {
        this.fetchAccount(this.id);
    },
    methods: {
        ...mapActions([
            "updateAccount",
            "fetchAccount",
            "deleteAccount"
        ]),
        onSubmit() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    this.updateAccount({
                        id: this.accountDto.id,
                        fullName: this.accountDto.fullName,
                        username: this.accountDto.username,
                        password: this.accountDto.password,
                        userType: this.accountDto.userType
                    });
                    this.$refs.observer.reset();
                }
            });
        },
    },
    beforeDestroy() {
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
